import React from 'react';

import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'

import Tiles from './components/games/Tiles';
import Pairs from './components/games/Pairs';
import Infinite from './components/games/Infinite';
import Timed from './components/games/Timed';
import Same from './components/games/Same';
import Lines from './components/games/Lines';
import Unpaired from './components/games/Unpaired';

import Comments from './components/Comments';

import { Helmet } from 'react-helmet';

import Shares from './components/Shares';
import Minigames from './components/games/Minigames';
import Moregames from './components/games/Moregames';

import GoogleAd from './components/GoogleAd';

import levels_lines from './static/json/levels_lines.json';
import general from './static/json/general.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function getResult(game, tileset, param) {
	return localStorage.getItem(game + "-" + tileset + "-" + param);
}

function setResult(game, tileset, param, result) {
	return localStorage.setItem(game + "-" + tileset + "-" + param, result);
}


function Main({view, game, play, playAgain, finishGame, tileset, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function codeForGame() {
		if(game == "tiles") {
			return (<Tiles onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "pairs") {
			return (<Pairs onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "infinite") {
			return (<Infinite onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "timed") {
			return (<Timed onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "same") {
			return (<Same onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "lines") {
			return (<Lines onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "unpaired") {
			return (<Unpaired onTilesFinish={finishGame} tileset={tileset}/>);
		}
	}

	var code = "";
	if(view === "play") {
    	code = (
    	    <>
    	    <div className="game">
    	        {codeForGame()}
    	        <br/>

    	    </div>
    	    </>);

    } else if(view === "finish") {

		var lastScore = getResult(game, tileset, "last");
		var lastImage = getResult(game, tileset, "image");
		var dates = getResult(game, tileset, "dates").split(" ");

		var resultTable = (<div><table className="resultsTable">
			<thead><td>{t('date')}</td><td>{t('bestScore')}</td></thead>
			<tbody>
			{ dates.map(date => <tr key={date}><td>{date}</td><td>{getResult(game, tileset, date)}</td></tr> )}
			</tbody></table></div>);

		function textOnFinish(lastScore) {

			if(game == "same" || game == "unpaired" || game == "infinite" || game == "timed") {
				return t("sameFinishText");
			}

			const bestScores = {
				"tiles": 45,
				"pairs": 24
			}
			if(game == "lines") {
				return "Great score! Play again or choose different level!";
			}

			if(bestScores[game] == lastScore) {
				return t("congrats");
			} else {
				return t("canYou45").replace("X", bestScores[game]);
			}

		}
		function chooseTileset() {
			if(game == "tiles") {
				window.location.href =  "/" + lang + "/tilesets";
			} else {
				window.location.href =  "/" + lang + "/" + game + "/tilesets" ;
			}
		}

		function getStars(points) {

			var possible = 0;
			if(game == "lines") { possible = levels_lines[tileset]["best"];}
			else if(game == "tiles") { possible = 45; }
			else if(game == "pairs") { possible = 24; }
			else if(game == "unpaired") { possible = 30; }
			else if(game == "infinite") { possible = 45; }
			else if(game == "timed") { possible = 45; }
			else if(game == "same") {
				const bestInSame = {
	                "two": 5,
	                "three": 10,
	                "four": 21,
	                "five": 30,
	            }
				possible = bestInSame[tileset];
			}
			var starsAmount = 1;

			if(game == "same" || game == "lines") {
				if(points <= possible) {
					starsAmount = 3;
				} else if (points <= possible + 3) {
					starsAmount = 2;
				}

			} else if(game == "unpaired") {
				if(points <= possible) {
					starsAmount = 3;
				} else if (points <= possible + 30) {
					starsAmount = 2;
				}

			} else {
				if(points >= possible) {
					starsAmount = 3;
				} else if (points >= possible - 5) {
					starsAmount = 2;
				}
			}


			return (
					<div>

						<br/>
						{(starsAmount >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
						: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
						{(starsAmount >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
						: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
						{(starsAmount >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
						: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
						<h4>You got {starsAmount} {(starsAmount > 1) ? "stars" : "star" }! </h4>

						<br/>
					</div>
				);

		}


        code =  (
            <div className="finish">
                <h1>{t('win')}</h1>
	            <h4>
	                {
	                    (game == "unpaired") ?  ("Your time is " + lastScore + " seconds!") :  (t('yourScore') + " " + lastScore)
	                }
	            </h4>
	            { getStars(lastScore) }

				<p>{ textOnFinish(lastScore) }</p>
				{
					game == "tiles"
					? <a href="/howtoplay">Check how to get more points</a>
					: <></>
				}

	            <div>
	            <Button variant="success" className="default-btn" onClick={chooseTileset}>{t("chooseTileset")}</Button><br/>
	                    <Button className="small-btn" onClick={playAgain}>{t('playAgain')} </Button>


	            </div>

				<Shares game={game} tileset={tileset} score={lastScore} image={lastImage}/>
	            <br/><br/>

				<h4>Your scores history</h4>
	            { resultTable }


            </div>);
    }

    return (
        <>
	        <div className="mainPart">
		        <Container>
		            <GoogleAd type="top"/>
		            <Row>
		                { code }
		            </Row>
		            <GoogleAd type="bottom"/>
		        </Container>
	        </div>
	        <br/><br/>
			<Comments/>
	        <Moregames/>
        </>
      );
}



function Game({game, lang, tileset}) {

	const { t } = useTranslation();
    const [currentView, setCurrentView] = useState("play");
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    function finishGame(result, image="") {

		var best = getResult(game, tileset, "best");

		if(best == null) {
			var finishedSoFar = 1;
			if(game == "lines") {
				const tilesets = general[game];
				for(var i = 0; i < tilesets.length; i++) {
					var ts = tilesets[i]["name"];
					if(getResult(game, ts, "best") != null) {
						finishedSoFar += 1;
					}
				}
			} else if(game == "same") {
				const tilesets = general[game];
				for(var i = 0; i < tilesets.length; i++) {
					var ts = tilesets[i];
					if(getResult(game, ts, "best") != null) {
						finishedSoFar += 1;
					}
				}
			} else {
				const newTilesets = general["waiting"];
				for(var i = 0; i < newTilesets.length; i++) {
					var ts = newTilesets[i]["name"];
					if(getResult(game, ts, "best") != null) {
						finishedSoFar += 1;
					}
				}
			}

			ReactGA.event({
	             category: 'Game',
	             action: 'FinishedGames_' + game,
	             value: finishedSoFar
	        });
		}

        setResult(game, tileset, "last", result);
        setResult(game, tileset, "image", image);
        console.log(image);

		if(getResult(game, tileset, "best") == null) {
			setResult(game, tileset, "best", result);
		}

		if(game == "tiles" || game == "pairs" || game == "infinite" || game == "timed") {
			if(getResult(game, tileset, "best") < result) {
				setResult(game, tileset, "best", result);
			}
		} else {
			if(getResult(game, tileset, "best") > result) {
            	setResult(game, tileset, "best", result);
            }
		}


        var today = new Date();
		var dateToday = today.getFullYear() + "-" + (today.getMonth()+1) + "-" + today.getDate();

        var todayBest = getResult(game, tileset, dateToday);
        if(todayBest == null) {
            var allDates = getResult(game, tileset, "dates");
            if(allDates === null) {
                allDates = dateToday;
            } else {
                allDates = allDates + " " + dateToday;
            }
            setResult(game, tileset, "dates", allDates);
            setResult(game, tileset, dateToday, result);
        }

        if(game == "tiles" || game == "pairs") {
			if(todayBest < result) {
				setResult(game, tileset, dateToday, result);
			}
		} else {
			if(todayBest > result) {
            	setResult(game, tileset, dateToday, result);
            }
		}


        ReactGA.event({
            category: 'Game',
            action: 'FinishGame',
            value: result
        });

		setCurrentView("finish");
	}

	function play() {
		ReactGA.event({
            category: 'Game',
            action: 'StartGame'
        });
    	setCurrentView("play");
    }

	function playAgain() {
		ReactGA.event({
            category: 'Game',
            action: 'StartGameAgain'
        });
    	setCurrentView("play");
    }


	function tilesetToUpper({tileset}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}


	const title = "Unlimited Tiles - " + ((game == "tiles") ? t("title") :  t(game + "Title")) + " - " + tilesetToUpper({tileset});
	const description = "";


	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.unlimited-tiles.com/" + lang + "/" + tileset} />
                <meta property="og:url" content={"https://www.unlimited-tiles.com/" + lang + "/" + tileset }  data-react-helmet="true" />
                <meta property="og:image" content={"https://www.unlimited-tiles.com/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={title}/>


            </Helmet>

			<Main view={currentView} game={game} play={play} playAgain={playAgain} finishGame={finishGame} tileset={tileset} lang={lang}/>


		</>

	);
};

export default Game;
