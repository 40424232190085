import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Comments from './Comments';
import '../static/css/tilesets.css';
import GoogleAd from './GoogleAd';

import levels_lines from '../static/json/levels_lines.json';

import general from '../static/json/general.json';

function bigName(gameName) {
		const names = {
        	"lines": "COLOR LINES",
        	"same": "SAME GAME"
        };
        if(gameName in names) {
        	return names[gameName];
        } else {
			return gameName.toUpperCase();
		}
}



function Tileset({game, tileset, stars, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	function bestScore() {
		var best = localStorage.getItem(game + "-" + tileset + "-best");

		if(best != undefined) {
			return (<>{t("bestSoFar")}: {best}</>)
		} else {
			return (<>  </>)
		}
	}

	function getUrl({game, tileset}) {
		if(game === "tiles") {
	        return ("/" + detectedLanguage + "/" + tileset);
	    } else {
	        return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	    }
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
    }

	function playTileset({available, game, tileset}) {
		if(available) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	function textToUpper({tileset}) {
		const titles = {
        	"rubik": "RUBIK HARD",
        	"rubik3": "RUBIK EASY",
        	"bwcircles": "B&W CIRCLES",
        	"nightgarden": "NIGHT GARDEN",
        	"blacksquares": "BLACK SQUARES",
        	"whitelines": "WHITE LINES",
        	"mondrian": "PIET MONDRIAN",
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}

	function getImage(available, game, tileset) {
		if(game === "lines") {

			if(available) {
		        return(<Card.Img variant="top" className="squareTilesetImage" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
		    } else {
		        return(<Card.Img variant="top" className="squareTilesetImage newTileset" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
	        }
		}

		var coverTileset = (<></>);
		if(game == "timed" || game == "infinite") {
			coverTileset = (<Card.Img style={{"position": "absolute"}} variant="top" src={"/images/games/" + game + "/cover.png"}  />);
		}
		if(game == "unpaired" || game == "timed" || game == "infinite" ) {game="tiles";}
		if(available) {
	        return (<> {coverTileset}
	        <Card.Img alt={"tiles game unlimited " + tileset } variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  /></> );

	    } else {
	        return (<Card.Img className="newTileset" variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  />);
        }
	}


	function getStars() {
		return (
			<div>
				{(stars >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}

			</div>
		);

	}



	if(date === "") {
		available = true;
	} else {
		var available = false;
        const todayDate = new Date();
        const dateDate = new Date(date);
		if(dateDate <= todayDate) {
			available = true;
		}
	}

    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => playTileset({available, game, tileset})} className='tilesetCard'>
                {
                    getImage(available, game, tileset)
                }

                <Card.Body>
                    <Card.Title>{textToUpper({tileset})}</Card.Title>
                    <Card.Text className="tilesetDate">{date}</Card.Text>
                    { getStars() }
                    <Card.Text>{bestScore()}</Card.Text>
                    {
                        available
                        ? <Button href={getUrl({game, tileset})} className="small-btn" variant="primary"
                            onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                        : <>Tileset available from<br/> {date} </>
					}
                </Card.Body>
            </Card>
        </Col>)

    );
}

function getTilesets(game) {

	if(game == "tiles" || game == "infinite" || game == "pairs"  || game == "unpaired"  ||  game == "timed"  || game == "lines" ) {
		var ts, tilesets = [];
		var listName = (game == "lines") ? "lines" : "waiting";
		const todayDate = new Date();
		var dateDate = new Date();

		for(var i = 0; i < general[listName].length; i++) {
			ts = general[listName][i];
			dateDate = new Date(ts["date"]);
			if(dateDate <= todayDate) {
            	tilesets.push(ts);
            } else {
                var day = 60 * 60 * 24 * 1000;
                if(dateDate <= new Date(todayDate.getTime() + day)) {
                    tilesets.push(ts);
                }
            }
		}
		return tilesets;
	} else {
		return general[game].map(tset => ({"name": tset, "date": ""}));
	}
}
function getTilesetsAvailable(game) {

	if(game == "tiles" || game == "infinite" || game == "pairs"  || game == "unpaired"  ||  game == "timed"  || game == "lines" ) {
		var ts, tilesets = [];
		var listName = (game == "lines") ? "lines" : "waiting";
		const todayDate = new Date();
		var dateDate = new Date();
		console.log(todayDate);

		for(var i = 0; i < general[listName].length; i++) {
			ts = general[listName][i];
			dateDate = new Date(ts["date"]);
			if(dateDate <= todayDate) {
            	tilesets.push(ts);
            }
		}
		return tilesets;
	} else {
		return general[game].map(tset => ({"name": tset, "date": ""}));
	}
}



const Tilesets = ({game, language}) => {
	const { t } = useTranslation();
	var tilesets = getTilesets(game);
	var tilesetsAvailable = getTilesetsAvailable(game);
	const [linkCopied, setLinkCopied] = useState(false);

	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}

	function getStars({tileset, game}) {
		tileset = tileset["name"];
		var best = localStorage.getItem(game + "-" + tileset + "-best");

		var possible = 0;
		if(game == "lines") { possible = levels_lines[tileset]["best"];}
		else if(game == "tiles") { possible = 45; }
		else if(game == "pairs") { possible = 24; }
		else if(game == "unpaired") { possible = 30; }
		else if(game == "infinite") { possible = 45; }
		else if(game == "timed") { possible = 45; }
		else if(game == "same") {
			const bestInSame = {
                "two": 5,
            	"three": 10,
            	"four": 21,
            	"five": 30,
            }
			possible = bestInSame[tileset];
		}

		var starsAmount = 0;
		if(best != undefined) {
			starsAmount = 1;

			if(game == "same" || game == "lines") {
				if(best <= possible) {
					starsAmount = 3;
				} else if (best <= possible + 3) {
					starsAmount = 2;
				}
			} else if(game == "unpaired") {
				if(best <= possible) {
					starsAmount = 3;
				} else if (best <= possible + 30) {
					starsAmount = 2;
				}
			} else {
				if(best >= possible) {
					starsAmount = 3;
				} else if (best >= possible - 5) {
					starsAmount = 2;
				}
			}
		}
		return starsAmount;
	}

	function getStarsNew({tileset, game}) {
		return getStars({tileset, game});
	}

	function countStars(game) {
		var stars = 0;
		var tsets = getTilesets(game);

		for(var i = 0; i < tsets.length; i++) {
			var tileset = tsets[i];
			stars += getStars({tileset, game});
		}
		return stars;
	}

	function getAllStars() {
		var stars = {};
		var games = ["tiles", "pairs", "infinite", "lines", "same", "unpaired", "timed"];
		for(var i = 0; i < games.length; i++) {
			stars[games[i]] = countStars(games[i]);
		}

		return stars;
	}

	function getInstruction(game) {
		var inst = {
			"tiles": "Look for tiles that have a common element. Score as many points as possible.",
			"pairs": "Find pairs of identical tiles.",
			"infinite": "Infinite version - tiles recreate when cleared.",
			"lines": "Draw the goal image in as few moves as possible.",
			"same": "Clear the board in as few moves as possible.",
			"unpaired": "Find the square that doesn't have a pair!",
			"timed": "You have 100 seconds to create the longest combo."
		}
		return inst[game];
	}

	function copyLink({game, setLinkCopied}) {
		ReactGA.event({
	        category: 'Game',
	        action: 'ShareResult'
	    });
	    setLinkCopied(true);
	    var link = "https://www.unlimited-tiles.com/";
	    var textToShare = "Hi! My score in Unlimited Tiles is ⭐ " + countStars(game) + "/" + (3*tilesetsAvailable.length) + ". Can you beat me?";
	    var image = "";
	    for(var i = 0; i < tilesetsAvailable.length; i++) {
	        var tileset = tilesetsAvailable[i]["name"];
        	var best = localStorage.getItem(game + "-" + tileset + "-best");
	        if(best == 45) {image += "🟩";}
	        else if(best >= 40) {image += "🟨";}
	        else if(best == null) {image += "⬜";}
	        else {image += "🟧"; }
	    }
	    navigator.clipboard.writeText(textToShare + "\n" + image +  "\n\n" + link);

	}


	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Unlimited Tiles - {gameToUpper({game})} - Available Tilesets</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + game} />
	            <link rel="canonical" href={"https://www.unlimited-tiles.com/" + detectedLanguage + "/tilesets"} />
	            <meta property="og:url" content={"https://www.unlimited-tiles.com/" + detectedLanguage + "/tilesets"} />
	            <meta property="og:image" content="https://www.unlimited-tiles.com/banner2.png" />
	            <meta property="og:title" content={"Unlimited Tiles - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>


	        <div className="mainPart">
		        <Container>
		            <GoogleAd type="tilesetsTop"/>
		            <br/>

		            <h1>{bigName(game)}</h1>
					<h2>{ getInstruction(game) }</h2>
					{
		                game === "same"
		                ? <h2 className="intro">{ t("chooseFavorite") }</h2>
		                : <h2 className="intro">{ t("newTilesetAvailable") }</h2>
		            }
		            <h2>
		                <img className="biggerStar" src={"/images/tilesets/lines/star.png"}/> { countStars(game) + " / " + (3*tilesetsAvailable.length) }
		                    {
		                        game == "tiles"
		                        ? <Button style={{"margin-left": "20px", "margin-bottom": "8px"}} className="small-btn" variant="primary" onClick={() => copyLink({game,setLinkCopied})}>{t("copyLink")}</Button>
		                        : <></>
		                    }
		                </h2>
		                {
		                    linkCopied
		                    ? <center><h4>Your results copied to the clipboard :) </h4></center>
		                    : <></>
		                }


		            {
		                <Row>
		                    {tilesets.map(tileset => ( <Tileset game={game} tileset={tileset["name"]} stars={getStars({tileset, game})}
		                        key={tileset["name"]} date={tileset["date"]} />))}
		                </Row>
		            }

		            <GoogleAd type="tilesetsBottom"/>
		        </Container>
				<br/>
	        </div>


			<Comments/>

			<Minigames/>
        </div>
      );

    return tilesetHtml
}
export default Tilesets;

