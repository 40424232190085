import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';


import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';



const Cookie = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Number Guesser - Free Calming and Relaxing Games Online</title>
            <meta name="description" content="Number Guesser - free calming and relaxing games online" />
            <link rel="canonical" href="https://www.number-guesser.com/ourgames" />
            <meta property="og:url" content="https://www.number-guesser.com/ourgames" />
            <meta property="og:image" content="https://www.number-guesser.com/banner.png" />
            <meta property="og:title" content="Number Guesser - Free Calming and Relaxing Games Online"/>
            <meta property="og:description" content="Number Guesser - play our free calming and relaxing games online. "/>
        </Helmet>


        <Container>
            <h1>Our Relaxing Games</h1>
			<p>We are excited to introduce our website, specially designed for fans of relaxing online games.
			We've carefully curated a selection of games that we believe you'll love, including titles like
			<b> Tiles</b>, <b>Unpaired</b>, <b>Pairs</b>, <b>Same Game</b>, and <b>Color Lines</b>.</p>
			<p>But that's not all! We're constantly working on adding <b>new games and levels</b>,
			so be sure to check back often. Whether you're looking to relax after a long day or just enjoy some casual gaming fun,
			our site has something for everyone. Come explore and discover your next favorite game! </p>
			<br/>

			<img className="bigImg" alt="tiles game " src="/images/ourgames/tiles.png" />
			<h3>Tiles</h3>
			<p>Our first game is <b>Tiles</b>. On a 5x6 grid, we have 30 squares. Each of them consists of 3 layers.
			Your task is to find pairs of squares that have at least one common layer. For each correct move,
			you will get one point, but if you make a mistake, your current combo will be reset to zero.
			Your goal is to achieve the longest possible combo, so make sure to think through your moves carefully!</p>
			<Button variant="success" className="small-btn" href="/">PLAY TILES</Button>
			<br/><br/><br/>

			<h3>Infinite Tiles</h3>
			<p><b>Infinite Tiles</b> is an endless version of the game Tiles. You can play as long as you want to improve your score.
			New tiles appear in place of the disappearing ones, ensuring the board will never be empty!</p>
			<Button variant="success" className="small-btn" href="/en/infinite">PLAY INFINITE</Button>
			<br/><br/><br/>

			<img className="bigImg" alt="find pairs game" src="/images/ourgames/pairs.png" />
			<h3>Pairs</h3>
			<p>The next game is <b>Pairs</b>. The task here is very simple - you need to find pairs of identical tiles.
			Don't make mistakes to achieve the highest score. This is our most calming game.</p>
			<Button variant="success" className="small-btn" href="/en/pairs">PLAY PAIRS</Button>
			<br/><br/><br/>

			<img className="bigImg" alt="fastest game" src="/images/ourgames/unpaired.png" />
			<h3>Unpaired</h3>
			<p><b>Unpaired</b> is like the opposite of Pairs. Here you have to find the square that doesn't have a pair.
			An additional challenge is the ticking clock; to earn 3 stars, you must solve the task in under 30 seconds.
			If you make a mistake, you will receive a penalty!</p>
			<Button variant="success" className="small-btn" href="/en/unpaired">PLAY UNPAIRED</Button>
			<br/><br/><br/>

			<img className="bigImg" alt="color lines game " src="/images/ourgames/lines.png" />
			<h3>Color lines</h3>
			<p>In the game <b>Color Lines</b>, your task is to recreate the illustration by drawing lines in one color.
			Do this using as few moves as possible to earn 3 stars. It's not as simple as it might seem!</p>
			<Button variant="success" className="small-btn" href="/en/same">PLAY COLOR LINES</Button>
			<br/><br/><br/>

			<img className="bigImg" alt="same game unlimited" src="/images/ourgames/same.png" />
			<h3>Same game</h3>
			<p>And then there's Same Game - remove all the tiles by eliminating areas of the same color.
			Here, a well-thought-out strategy is crucial; the move that seems best isn't always the best.</p>
			<Button variant="success" className="small-btn" href="/en/same">PLAY SAME GAME</Button>
			<br/><br/><br/>





        </Container>
        </div>
      );

    return tilesetHtml
}

export default Cookie;

