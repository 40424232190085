import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import general from './../../static/json/general.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');


function Square({value, id, isCurrent, onSquareClick, tileset}) {
	var classes = isCurrent ? "square7 currentSquare" : "square7";
	var moreStyle = (id == 24) ? {visibility:"hidden"} : {};

	var tiles = "";

	function getImage(num) {
		var type = ["a", "b", "c"];
		if(value[num] > 0) {
			return <div className="image"><img src={"/images/tilesets/" + tileset + "/" + type[num] + value[num] + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/" + tileset + "/background.png"} alt="X"/></div>;
	}

    return (
        <td className={classes} style={moreStyle} onClick={onSquareClick}>
            { getBackground() }
            { getImage(0) }
            { getImage(1) }
            { getImage(2) }


        </td>
    );
}

function getRandomStart() {

	var board = Array(49).fill([0,0,0]);
	var boardSmall = [
		[1,1,1], [1,2,1], [1,1,2], [1,3,2], [1,2,3], [1,3,3], [1,1,4], [1,2,5],
		[2,1,1], [2,2,1], [2,1,2], [2,3,2], [2,2,3], [2,3,3], [2,1,4], [2,2,5],
		[3,1,1], [3,2,1], [3,1,2], [3,3,2], [3,2,3], [3,3,3], [3,1,4], [3,2,5]
	];
	for(var i = 0; i < 24; i++) {
    	board[i] = boardSmall[i];
    	board[i+24] = boardSmall[i];
    }

    const emptySquare = [0,0,0];

	board.sort(() => Math.random() - 0.5);
	board.sort(() => Math.random() - 0.5);

	const empty = board.findIndex((element) => element[0] == 0 && element[1] == 0 && element[2] == 0);

	board[empty] = board[24];
	board[24] = [0,0,0];

	return board;
}

export default function Pairs({onTilesFinish, tileset}) {
	const [lastPos, setLastPos] = useState(-1);
    const [squares, setSquares] = useState(getRandomStart);

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] = useState(0);
    const [tilesLeft, setTilesLeft] = useState(48);

	function handleClick(clicked) {
		if (clicked === lastPos || (squares[clicked][0] === 0 && squares[clicked][1] === 0 && squares[clicked][2] === 0)) {
            return;
        }

        const nextSquares = squares.slice();
        if (lastPos === -1) {
            setLastPos(clicked);
        } else {
            var correct = 0;
            var newTilesLeft = tilesLeft;

            if(squares[clicked] === squares[lastPos]) {
                nextSquares[clicked] = [0,0,0];
                nextSquares[lastPos] = [0,0,0];
                correct = 1;
                newTilesLeft -= 2;
            }

            if(correct) {
                setTilesLeft(newTilesLeft);
                if(nextSquares[clicked][0] === 0 && nextSquares[clicked][1] === 0 && nextSquares[clicked][2] === 0) {
                    setLastPos(-1);
                } else {
                    setLastPos(clicked);
                }
                var newCurrentCombo = currentCombo + 1;
                var newLongestCombo = longestCombo;
                setCurrentCombo(newCurrentCombo);
                if(newCurrentCombo > longestCombo) {
                    newLongestCombo = newCurrentCombo;
                    setLongestCombo(newLongestCombo);
                }
            } else {
                setCurrentCombo(0);
            }
            if(newTilesLeft <= 0) { //Finish game

				onTilesFinish(newLongestCombo);
            }
        }
        setSquares(nextSquares);

    }

	const rows = [0,1,2,3,4,5,6];
	const columns = [0,1,2,3,4,5,6];

	function lineCode(num) {
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={7*num+column} id={7*num+column} value={squares[7*num+column]} isCurrent={lastPos === 7*num+column}
					onSquareClick={() => handleClick(7*num+column)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoFindPairs");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ t("currentCombo") }<br/><h1>{ currentCombo }</h1></div>
					<div className="col1">{ t("longestCombo") }<br/><h1>{ longestCombo }</h1></div>
					<div className="col1">{ t("partsLeft") }<br/><h1>{ tilesLeft }</h1></div>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

