import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import levels from './../../static/json/levels_lines.json';
import './../../static/css/lines.css';

import 'animate.css';



function Square({cc, value, type, onSquareClick, tileset}) {

	function getImage() {
		if(type === "square") {
			return <div className="image"><img src={"/images/tilesets/lines/d" + value + ".png"} alt="Tile"/></div>;
		} else if(type === "column") {
			return <div className="image"><img src={"/images/tilesets/lines/down" + value + ".png"} alt="Tile"/></div>;
		} else if(type === "row") {
			return <div className="image"><img src={"/images/tilesets/lines/right" + value + ".png"} alt="Tile"/></div>;
		}
	}

	if(type === "empty") {
		return ( <td className={"linesSquare" + cc} style={{"visibility":"hidden"}} onClick={onSquareClick}> </td> );
	}

    return (
        <td className={"linesSquare" + cc} onClick={onSquareClick}> { getImage() }</td>
    );
}

function getEmptyStart(tileset, colors) {

	const board = [];
	const R = 7 + colors, C = 7 + colors;

    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }

	return board;
}

export default function Tiles({onTilesFinish, tileset}) {

	const colors = ("colors" in levels[tileset]) ? levels[tileset]["colors"] : 2;
    const [squares, setSquares] = useState(getEmptyStart(tileset, colors));

    const [moves, setMoves] = useState(0);
    const [lastMove, setLastMove] = useState(0);
	const goal = levels[tileset]["board"];

	function showGoal() {
		return (
            <div className="goalBoard">
              {goal.map((row, rowIndex) => (
                <div key={`row-${rowIndex}`} className="goalRow">
                  {row.map((col, colIndex) => (

                      <div key={`col-${rowIndex}${colIndex}`} className={"goalSquare square" + col}></div>

                  ))}
                </div>
              ))}
            </div>
          )
	}

	function checkIfCorrect() {
		var errors = 0;
		//var str = "[";
		for(var i = 0; i < 7; i++) {
			//str += "[";
			for(var j = 0; j < 7; j++) {
				//str += squares[i+colors][j+colors].toString();
				//if(j < 6) {str += ","; }

				if(squares[i+colors][j+colors] !== goal[i][j]) {
					errors++;
				}
			}
			//str += "]";
			//if(i < 6) {str += ","; }
		}
		//str += "]";

		if(errors === 0) {
			onTilesFinish(moves+1);
		}
	}

	function wypiszSquares(kwadraciki) {
		kwadraciki = kwadraciki.slice(colors);
		for(var i = 0; i < 7; i++) {
			kwadraciki[i] = kwadraciki[i].slice(colors);
		}
		console.log(kwadraciki);
	}

	function colorRow(row, color) {
		const nextSquares = squares.slice();

		for(var i = colors; i < 7 + colors; i++) {
			nextSquares[row][i] = color;
		}
		setSquares(nextSquares);
		wypiszSquares(nextSquares);
		checkIfCorrect();
	}

	function colorColumn(column, color) {
		const nextSquares = squares.slice();

		for(var i = colors; i < 7 + colors; i++) {
			nextSquares[i][column] = color;
		}
		setSquares(nextSquares);
		wypiszSquares(nextSquares);
		checkIfCorrect();
	}


	function handleClick(row, column) {

		if(lastMove == 100*row+column) {
			return;
		}
		setLastMove(100*row+column);
		if(column < colors) {
			colorRow(row, column+1);
			setMoves(moves+1);
		}
		if(row < colors) {
			colorColumn(column, row+1);
			setMoves(moves+1);
		}
    }


	const R = 7 + colors;
	const C = 7 + colors;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var j = 0; j < C; j++) {
		columns[j] = j;
	}

	function lineCode(row) {
		return (
			<tr key={100+row} className="board-row">{

					columns.map(column => (

						(row < colors && column < colors)  ? <Square cc={colors} tileset={tileset} key={C*row+column} value={0} type="empty" onSquareClick={() => handleClick(row,column)}/>
						: (row < colors)  ? <Square cc={colors} tileset={tileset} key={C*row+column} value={(row+1)} type="column" onSquareClick={() => handleClick(row,column)}/>
						: (column < colors)  ? <Square cc={colors} tileset={tileset} key={C*row+column} value={(column+1)} type="row" onSquareClick={() => handleClick(row,column)}/>
						: (<Square cc={colors} tileset={tileset} key={C*row+column} value={squares[row][column]} type="square"
                            						onSquareClick={() => handleClick(row,column)} />)


					))

				}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("linesMove1");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="linesGameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ t("GOAL") }<br/>{showGoal()}</div>
					<div className="col1">{ "" }<br/><h1>{ "" }</h1></div>
					<div className="col1">{ t("MOVES") }<br/><h1>{ moves }</h1></div>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

